import { Box, Button, Dialog, FormControlLabel } from '@mui/material';
import styled from 'styled-components';

import { T2_medium, colors } from '../../styles';

export const DIALOG_HEIGHT = 640;
export const DIALOG_WIDTH = 600;

export const StyledFormControlLabel = styled(FormControlLabel)`
	margin-right: 0;
	padding-left: 3px;

	span {
	}

	.MuiTypography-root {
		font-size: 14px;
	}
`;

export const StyledButton = styled(Button)`
	height: 30px;
`;

export const StyledEmailDialog = styled(Dialog)`
	& .MuiPaper-root {
		max-width: 650px;
	}
`;

export const StyledEmailDialogTitle = styled(Box)<{ $isFocused: boolean }>`
	color: ${colors.grey[900]};
	padding-block: 1rem;
	position: sticky;
	min-height: 54px;
	padding: 16px;
	top: 0;
	z-index: 20;
	${T2_medium};
	border-bottom: 1px solid ${colors.border.main};
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	column-gap: 16px;
`;

export const StyledDivWrapper = styled.div`
	background-color: ${colors.common.white};
	position: absolute;
	width: ${DIALOG_WIDTH}px;
	height: ${DIALOG_HEIGHT}px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	-webkit-box-shadow: 9px 8px 49px -8px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 9px 8px 49px -8px rgba(0, 0, 0, 0.75);
	box-shadow: 9px 8px 49px -8px rgba(0, 0, 0, 0.75);

	.react-resizable-handle-se {
		height: 15px !important; /* Example height, use the desired value */
		cursor: grab;
	}
`;

export const StyledEmailTitleText = styled(Box)`
	max-width: calc(100% - 68px);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;
