import { Box, Button, CircularProgress, Portal } from "@mui/material";
import Draggable from "react-draggable";
import React, {
  useRef,
  type FC,
  memo,
  useState,
  useMemo,
  useEffect,
} from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { Resizable } from "react-resizable";

import {
  type ContactStatusType,
  ContactStatusEnum,
  OrderConnectionStatusEnum,
} from "@heylog-app/shared/types";

import { ModalClose, TabNavigation, TabNavigationItem } from "../ui";
import { Conversation } from "../conversation/conversation";
import {
  useContactName,
  useContacts,
  useConversationV2,
  useMessages,
  useMessagesV2,
  useOrderActions,
  useRelevantEvents,
  useSnackbar,
} from "../../hooks";
import { FlashSnackbar } from "../snackbars";
import {
  DIALOG_HEIGHT,
  DIALOG_WIDTH,
  StyledDialogContent,
  StyledDivTitle,
  StyledEmailDialogTitle,
  StyledTabNavigation,
  StyledWrapperDiv,
} from "./conversation-dialog.styles";
import { SidebarLayoutContent } from "../sidebar-layout";
import { ErrorBoundary } from "../error-boundary";
import { ContactInformation } from "../contact-information";
import { colors } from "../../styles";
import { ConversationManagementTab } from "../conversation-management-tab/conversation-management-tab";
import { ROUTES } from "../../util";
import { TodoCheckbox } from "../todo-checkbox/todo-checkbox";
import { NUMBER_OF_ITEMS_TO_FETCH } from "../conversation/comversation-constants";

import type { ResizeCallbackData } from "react-resizable";
import type { PropsWithChildren } from "react";
import type { ChatAppDialogPayloadInterface } from "../../providers";

const LoaderWrapper: React.FC<PropsWithChildren<{ isLoading: boolean }>> = ({
  children,
  isLoading,
}) => {
  if (isLoading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="300px"
      >
        <CircularProgress variant="indeterminate" />
      </Box>
    );

  return (
    <Box height="100%" overflow="hidden">
      {children}
    </Box>
  );
};

enum ConversationDialogTabs {
  CHAT = "CHAT",
  INFO = "INFO",
  MANAGEMENT = "MANAGEMENT",
}

interface ConversationDialogPropsInterface {
  onClose: (id: string) => void;
  onClick: (id: string) => void;
  zIndex: number;
  id: string;
  payload: ChatAppDialogPayloadInterface;
  isShipmentPage: boolean;
  isFocused: boolean;
  idx: number;
}

export const ConversationDialog: FC<ConversationDialogPropsInterface> = memo(
  ({
    onClose,
    onClick,
    zIndex,
    id,
    payload,
    isShipmentPage,
    isFocused,
    idx,
  }) => {
    const nodeRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(DIALOG_HEIGHT);
    const [width, setWidth] = useState(DIALOG_WIDTH);
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState<
      ConversationDialogTabs | string
    >(ConversationDialogTabs.CHAT);
    const {
      workspaceId = 0,
      contactId = 0,
      conversationV2Id = 0,
      conversationId = 0,
      orderId = null,
    } = payload;
    const [contactFilter, setContactFilter] = useState<ContactStatusType>(
      ContactStatusEnum.ACTIVE
    );

    const {
      isLoadingMessages: isLoadingMessagesV2,
      isValidatingMessages: isValidatingMessagesV2,
    } = useMessagesV2({
      conversationV2Id: conversationV2Id.toString(),
      workspaceId: workspaceId.toString(),
      messagesToFetch: NUMBER_OF_ITEMS_TO_FETCH,
    });
    const {
      isLoadingMessages: isLoadingMessagesForRealtime,
      isValidatingMessages: isValidatingMessagesForRealtime,
    } = useMessages({
      conversationId: conversationId.toString(),
      workspaceId: workspaceId.toString(),
      messagesToFetch: NUMBER_OF_ITEMS_TO_FETCH,
    });

    const { isLoadingRelevantEvents, isValidatingRelevantEvents } =
      useRelevantEvents(workspaceId, contactId.toString());

    const isLoading =
      isLoadingMessagesForRealtime ||
      isLoadingMessagesV2 ||
      isLoadingRelevantEvents;
    //isValidatingMessagesForRealtime ||
    //isValidatingMessagesV2 ||
    //isValidatingRelevantEvents;
    const { contacts } = useContacts({});
    const { conversation } = useConversationV2(
      conversationV2Id,
      workspaceId.toString()
    );

    const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

    const currentContact = useMemo(() => {
      if (!contactId) return null;
      return contacts?.find((contact) => contact.id === contactId);
    }, [contacts, contactId]);

    const contactName = useContactName({
      firstName: currentContact?.firstName,
      lastName: currentContact?.lastName,
    });

    const isArchivedContact =
      currentContact?.status === ContactStatusEnum.ARCHIVED;

    const currentAssignment = useMemo(
      () =>
        currentContact?.orderConnections?.find((connection) =>
          connection.status !== OrderConnectionStatusEnum.ARCHIVED
            ? true
            : false
        ),
      [currentContact]
    );

    const { order: orderForConversation } = useOrderActions(
      orderId?.toString()
    );

    const orderInternalReference =
      orderForConversation?.labels.find(
        (label) => label.type === "ORDER_EXTERNAL_REFERENCE"
      )?.value || conversation?.orderReference;

    const pageWidth = window.innerWidth;
    const elementId = `popup-heading-${id}`;

    const xyAxisIndentPx = (idx + 1) * 16;

    const initialX = pageWidth - DIALOG_WIDTH;
    const preparedX = initialX - xyAxisIndentPx;

    const initialY = -DIALOG_HEIGHT;
    const preparedY = initialY - xyAxisIndentPx;

    const closeDialog = () => onClose(id);
    const onMouseDown = () => onClick(id);

    const handleNavigateToOrder = () =>
      navigate(
        generatePath(ROUTES.SHIPMENTS.DETAIL, {
          shipmentId: orderForConversation?.id.toString(),
          workspaceId: orderForConversation?.workspaceId?.toString(),
        })
      );

    const onResize = (
      event: React.SyntheticEvent,
      { size }: ResizeCallbackData
    ) => {
      if (nodeRef.current) {
        const windowRef = nodeRef.current.getBoundingClientRect();
        const maxWidth = window.innerWidth - windowRef.left;
        const maxHeight = window.innerHeight - windowRef.top;

        size.height <= maxHeight && setHeight(size.height);
        size.width <= maxWidth && setWidth(size.width);
      }
    };

    useEffect(() => {
      const contact = contacts?.find(
        (contact) => contact.id.toString() === String(contactId)
      );

      if (contact) setContactFilter(contact.status);
      // this is intended: i want this to be run only once, when coming from a deeplink
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (
        isArchivedContact &&
        selectedTab === ConversationDialogTabs.MANAGEMENT
      )
        setSelectedTab(ConversationDialogTabs.INFO);
    }, [isArchivedContact, selectedTab]);

    return (
      <Portal>
        <Draggable
          nodeRef={nodeRef}
          bounds="parent"
          defaultPosition={{ x: preparedX, y: preparedY }}
          onMouseDown={onMouseDown}
          handle={`#${elementId}`}
        >
          <Resizable
            height={height}
            width={width}
            onResize={onResize}
            minConstraints={[400, 500]}
            maxConstraints={[1500, 1500]}
          >
            <StyledWrapperDiv ref={nodeRef} style={{ zIndex, height, width }}>
              <FlashSnackbar
                controls={[stateSnackbar, openSnackbar, closeSnackbar]}
              />
              <StyledEmailDialogTitle
                $isFocused={isFocused}
                alignItems="center"
              >
                <StyledDivTitle id={elementId}>
                  {contactName}
                  {orderForConversation && (
                    <Button
                      sx={{ marginLeft: 1 }}
                      variant="text"
                      onClick={handleNavigateToOrder}
                    >
                      {orderInternalReference}
                    </Button>
                  )}
                  <ModalClose
                    BeforeContent={
                      !isArchivedContact && conversation ? (
                        <Box display="inline-block">
                          <TodoCheckbox
                            isShipmentPage={isShipmentPage}
                            conversation={conversation}
                            workspaceId={workspaceId}
                          />
                        </Box>
                      ) : undefined
                    }
                    closeModalFn={closeDialog}
                  />
                </StyledDivTitle>

                <StyledTabNavigation>
                  <TabNavigation>
                    <TabNavigationItem
                      label="contacts.tabs.chat"
                      active={selectedTab === ConversationDialogTabs.CHAT}
                      value={ConversationDialogTabs.CHAT}
                      onClick={setSelectedTab}
                    />
                    <TabNavigationItem
                      dataTest="contact-information-menu"
                      label="contacts.tabs.information"
                      active={selectedTab === ConversationDialogTabs.INFO}
                      value={ConversationDialogTabs.INFO}
                      onClick={setSelectedTab}
                    />
                    {!isArchivedContact && (
                      <TabNavigationItem
                        dataTest="contact-information-menu"
                        label="contacts.tabs.management"
                        active={
                          selectedTab === ConversationDialogTabs.MANAGEMENT
                        }
                        value={ConversationDialogTabs.MANAGEMENT}
                        onClick={setSelectedTab}
                      />
                    )}
                  </TabNavigation>
                </StyledTabNavigation>
              </StyledEmailDialogTitle>
              <LoaderWrapper isLoading={isLoading}>
                <StyledDialogContent>
                  <SidebarLayoutContent>
                    <ErrorBoundary>
                      {selectedTab === ConversationDialogTabs.CHAT && (
                        <Box sx={{ height: "100%" }}>
                          <Conversation
                            shouldFocusOnRender={false}
                            textareaWrapperStyles={{
                              position: "sticky",
                              bottom: "0",
                              backgroundColor: colors.common.white,
                            }}
                            contactFilter={contactFilter}
                            order={orderForConversation}
                            fetchData={{
                              ...payload,
                              orderId: orderForConversation?.id,
                              conversationV2Id: conversationV2Id,
                            }}
                          />
                        </Box>
                      )}
                      {selectedTab === ConversationDialogTabs.INFO && (
                        <Box sx={{ height: "100%", overflow: "auto" }}>
                          <ContactInformation
                            onCloseDialog={closeDialog}
                            contactId={payload.contactId}
                          />
                        </Box>
                      )}
                      {selectedTab === ConversationDialogTabs.MANAGEMENT &&
                        !isArchivedContact && (
                          <ConversationManagementTab
                            currentAssignment={currentAssignment}
                            orderForConversation={orderForConversation}
                            workspaceId={workspaceId}
                            contactId={contactId}
                            conversationId={conversationId}
                          />
                        )}
                    </ErrorBoundary>
                  </SidebarLayoutContent>
                </StyledDialogContent>
              </LoaderWrapper>
            </StyledWrapperDiv>
          </Resizable>
        </Draggable>
      </Portal>
    );
  }
);
