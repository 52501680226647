import useSWR from 'swr';
import { useCallback } from 'react';

import { UserRoleEnum } from '@heylog-app/shared/types';

import { getFetcher, getUserKey, getUsersKey, getWorkspaceKey } from '../util';
import { useApiClientContext, useLocalStorage, useMatchMutate } from '../hooks';

import type {
	CreateOrderConnectionReqInterface,
	I18nExceptionResponseInterface,
	OrderConnectionResInterface,
	UpdateWorkspaceReqInterface,
	UserResInterface,
	WorkspacePublicResInterface,
	WorkspaceResInterface,
} from '@heylog-app/shared/types';
import type { AxiosInstance, AxiosResponse } from 'axios';
import { CreateEntryParams, CreatePrivacyParams } from '../types';

export const useWorkspace = (workspaceId: string, user?: UserResInterface) => {
	const { apiClient } = useApiClientContext();

	const data = user?.workspaces?.find((w) => w.id === Number(workspaceId));

	const baseUserKey = getUserKey();
	const baseUsersKey = getUsersKey(workspaceId);
	const baseWorkspaceKey = getWorkspaceKey();
	const workspaceKey = baseWorkspaceKey + `/${workspaceId}`;

	const matchMutate = useMatchMutate();

	const updateWorkspace = useCallback(
		async (data: UpdateWorkspaceReqInterface) => {
			const result = await apiClient.patch(workspaceKey, data);
			matchMutate(new RegExp(`^${baseUserKey}.*$`));
			matchMutate(new RegExp(`^${baseUsersKey}.*$`));
			matchMutate(new RegExp(`^${baseWorkspaceKey}.*$`));
			return result.data;
		},
		[apiClient, matchMutate, baseUserKey, baseUsersKey, baseWorkspaceKey, workspaceKey],
	);
	console.log('data', data)
	return {
		workspace: data,
		updateWorkspace,
		isLoading: !data,
	};
};

export const useWorkspaceByJoinKey = (apiClient: AxiosInstance, joinKey: string) => {
	const { data, error, isLoading } = useSWR<WorkspacePublicResInterface>(
		`/workspaces/join/${joinKey}`,
		getFetcher(apiClient),
	);

	return {
		workspace: data,
		isLoading,
		isError: error,
	};
};

export const useWorkspaceById = (workspaceId: string) => {
	const { apiClient } = useApiClientContext();

	const baseWorkspaceKey = getWorkspaceKey();
	const workspaceKey = baseWorkspaceKey + `/${workspaceId}`;

	const { data, error, isValidating, isLoading } = useSWR<WorkspaceResInterface>(
		workspaceKey,
		getFetcher(apiClient),
	);

	return {
		workspace: data,
		isLoading,
		isError: error,
		isValidating,
	};
};

export const useOrganizationIdByWorkspaceId = (workspaceId: string) => {
	const { apiClient } = useApiClientContext();

	const baseWorkspaceKey = getWorkspaceKey();
	const workspaceKey = baseWorkspaceKey + `/${workspaceId}/organization`;

	const { data, error, isValidating, isLoading } = useSWR<number>(
		workspaceKey,
		getFetcher(apiClient),
	);

	return {
		organizationId: data,
		isLoading,
		isError: error,
		isValidating,
	};
};

export const useRelevantWorkspace = (user?: UserResInterface) => {
	const [lastVisitedWsId] = useLocalStorage('lastVisitedWsId', '');
	const lastVisitedWsIdInt = parseInt(lastVisitedWsId);
	let relevantWorkspace: number | undefined;

	if (user) {
		const { role, workspaces, guestConversations } = user;

		const isUserInWorkspace = workspaces?.some(
			(workspace) => workspace.id === lastVisitedWsIdInt,
		);

		if (role !== UserRoleEnum.CONVERSATION_GUEST && workspaces && workspaces.length > 0) {
			const foundWorkspace = workspaces.find(
				(workspace) => workspace.id === lastVisitedWsIdInt,
			);
			relevantWorkspace = foundWorkspace ? lastVisitedWsIdInt : workspaces[0]?.id;
		} else if (guestConversations && guestConversations.length > 0) {
			if (
				role === UserRoleEnum.CONVERSATION_GUEST &&
				lastVisitedWsId &&
				isUserInWorkspace
			) {
				relevantWorkspace = lastVisitedWsIdInt;
			} else {
				relevantWorkspace = guestConversations[0]?.workspaceId;
			}
		}
	}

	return { relevantWorkspace };
};
export const createPrivacyPolicy = (
	apiClient: AxiosInstance,
	formData: any,
	workspaceId?: string,
): Promise<
	AxiosResponse<any, I18nExceptionResponseInterface>
> => {
	try {
		return apiClient.post(`/workspaces/${workspaceId}/privacy-policy`, formData);
	} catch (error) {
		console.error('Failed to send media message:', error);
		throw error;
	}
};

export const useDPAById = (workspaceId: string) => {
	const { apiClient } = useApiClientContext();

	const { data, error, isValidating, isLoading } = useSWR<WorkspaceResInterface>(
		`/workspaces/${workspaceId}/privacy-policy`,
		getFetcher(apiClient),
	);

	return {
		dataCheck: data,
		isLoading,
		isError: error,
		isValidating,
	};
};
