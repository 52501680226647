import { Box, CircularProgress, type ButtonProps } from '@mui/material';

import { StyledButton } from './buttonV2.styles';
import { colors } from '../../../styles';

import type { FC, PropsWithChildren } from 'react';

export type ButtonV2VariantType = 'dark' | 'light' | 'warning' | 'error' | "outlined" | "outlined2" | "light2";
export type ButtonV2Props = ButtonProps & {
	$variant?: ButtonV2VariantType;
	$padding?: string;
	$height?: string;
	$typography?: 'T2' | 'T3';

	isLoading?: boolean;
};

export const ButtonV2: FC<PropsWithChildren<ButtonV2Props>> = ({
	children,
	isLoading,
	...props
}) => {
	return (
		<StyledButton variant="contained" {...props} disabled={isLoading || props.disabled}>
			{isLoading && (
				<Box
					sx={{
						position: 'absolute',
						top: '6px',
						right: '6px',
						color: colors.primary.main,
					}}
				>
					<CircularProgress color="inherit" size={12} />
				</Box>
			)}
			{children}
		</StyledButton>
	);
};
