import styled from 'styled-components';
import { Box, Button, ListItemText, Tab } from '@mui/material';
import { Link } from 'react-router-dom';

import { ButtonMenu, H2, T2, T2_medium, T3, T3_medium, colors } from '@heylog-app/frontend-lib/app';

import type { Theme } from '@mui/material/styles';

export const StyledDetailsTitle = styled.h1`
	${H2};
	color: ${colors.grey[900]};
	white-space: nowrap;
	margin: 0;
`;
export const TypoConversationDiv = styled.div`
	color: ${colors.primary.darker};
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
	letter-spacing: -0.3px;
`;
export const StyledReference = styled.div`
	color: ${colors.primary.darker};
	font-family: 'Inter', sans-serif;
	font-size: 40px;
	font-style: normal;
	font-weight: 400;
	line-height: 48px; 
	letter-spacing: -0.4px;
`
export const StyledGoBackLink = styled(Link)`
	${T3_medium};
	color: ${colors.primary.main};
	display: flex;
	gap: 8px;
	margin-bottom: 8px;
	text-decoration: unset;
	max-width: fit-content;
`;

export const StyledMenuButton = styled(ButtonMenu)`
	.MuiMenu-paper {
		min-width: 160px;
	
	}

		
`;

export const StyledModalHeader = styled.div`
	display: flex;
	flex-direction: row;
	color: ${({ theme }) => theme.palette.primary.darkV2};
`;

export const StyledBlock = styled.div`
	display: flex;
	flex-direction: row;
	gap: 4px;
`;

export const StyledCompanyTextBlock = styled.div`
	font-weight: bold;
`;

export const StyledModalLabel = styled.div`
	color: ${colors.grey[600]};
`;

export const StyledListItemText = styled(ListItemText)`
	color: ${colors.grey[900]};
	&:hover {
		background-color: rgb(209, 212, 242);
	}
`;

export const StyledDecoration = styled.div`
	height: 1px;
	background-color: ${colors.greyblue[205]};
`;

export const StyledButton = styled(Button)`
	height: 30px;
`;

export const StyledTab = styled(Tab)`
	text-transform: unset !important;
	${T2};
`;

export const StyledParagraph = styled.p`
	${T3};
	color: ${colors.grey[600]};
	margin: 0;
`;

export const StyledSpan = styled.span`
	color: ${colors.grey[900]};
`;

export const StyledHeaderCustomerLabelTitle = styled.div`
	color: ${colors.grey[600]};
	margin-right: 2px;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
	letter-spacing: -0.2px;
`;
export const StyledSpanCustomer = styled.div`
	color: ${colors.primary.darker};
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: -0.2px;
`
export const StyledHeaderLabelTitle = styled.span`
	margin-left: 2px;
	font-family: Inter;

`;
export const StyledTruckDiv = styled.div`
	display: flex;
	flex-direction: column;
	gap: 3px;
`
export const StyledTruckDivParent = styled.div`
	display: flex;
	flex-direction: row;
	gap: 15px;
`
export const StyledHeaderTruckLabelTitle = styled.div`
	color: ${colors.grey[600]};
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px; /* 133.333% */
	letter-spacing: -0.1px;
`;
export const StyledHeaderTruckLabelValue = styled.div`
	color: ${colors.primary.darker};
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: -0.2px;
`;

export const StyledCenterHeadingDiv = styled.div`

`;
export const StackLabelTypo = styled.div`
	color: ${colors.grey[700]};
	text-align: center;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px; 
	letter-spacing: -0.1px;
`
export const StyledDetailsBox = styled(Box)`
	min-height: 0 !important;
	margin-left: auto;

	p {
		margin: 0;
	}

	div {
		min-height: 0 !important;
		padding-left: 0;
	}
`;

export const StyledTypeLabel = styled.div`
	margin-left: 5px;
	margin-top: 2px;
`;

export const StatusLabel = styled('div')<{
	active: boolean;
	theme: Theme;
	completed: boolean;
	cancelled?: boolean;
	problem?: boolean;
}>(({ theme, completed, active, cancelled, problem }) => ({
	borderRadius: '4px',
	backgroundColor: cancelled
		? theme.palette.warning.light
		: completed
			? theme.palette.success.lighter
			: active
				? theme.palette.primary.lighter
				: problem ? '#FFCFCF' : theme.palette.grey[300],
	color: cancelled
		? theme.palette.warning.main
		: completed
			? theme.palette.success.main
			: active
				? theme.palette.primary.main
				: problem ? "#B20F0F" : theme.palette.grey[700],
	fontWeight: 500,
	padding: completed ? '2px 22px' : active ? '2px 4px' : '2px 16px',
}));

export const StyledDivLink = styled.div`
	color: blue;
	cursor: pointer;
`;

export const InfoWindowHeader = styled.div`
	border-bottom: 1px solid #ccc;
	padding-bottom: 8px;
`;

export const InfoWindowBody = styled.div`
	padding-top: 8px;
	width: 256px;
`;
