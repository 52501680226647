import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { EventPayloadEnum, MessagingTransportTypeEnum } from '@heylog-app/shared/types';

import { MessageInput, MessageInputBlocked } from '../ui';
import { OrderNotice } from '../ui/message-input/order-notice';
import { useContact, useConversation } from '../../hooks';

import type {
	FullContactResInterface,
	OrderResInterface,
	EntityEventResType,
	MessageResInterface,
} from '@heylog-app/shared/types';
import type { FC } from 'react';

type MessageInputRendererProps = {
	// the order context is used when the conversation is viewed inside an order
	// the contact order context is used when a conversation in the contacts view with an assigned order
	contactOrderContext?: { order: OrderResInterface; contact: FullContactResInterface };
	lastMessage?: MessageResInterface | EntityEventResType;
	conversationId?: string;
	contactId: string;
	shouldFocusOnRender?: boolean;
};

export const MessageInputRenderer: FC<MessageInputRendererProps> = ({
	lastMessage,
	contactOrderContext,
	conversationId: conversationIdProp,
	contactId: contactIdProp,
	shouldFocusOnRender,
}) => {
	const { t } = useTranslation();
	const { workspaceId = '', ...params } = useParams();
	const conversationId = conversationIdProp || params['conversationId'] || '';

	const [isChatBlocked, setIsChatBlocked] = useState(false);

	const [blockedInputText, setBlockedInputText] = useState<string>(
		t('chats.conversationBlocked'),
	);

	const { conversation } = useConversation(workspaceId, conversationId);
	const { contact, updateContact } = useContact(conversation?.contactId?.toString());

	useEffect(() => {
		updateContact();
	}, [lastMessage, updateContact]);

	useEffect(() => {
		// NOTE: how do we handle the active state for other transport types?
		if (conversation?.transportType === MessagingTransportTypeEnum.FACEBOOK_WHATSAPP) {
			if (contact?.lastMessageAt) {
				setIsChatBlocked(false);
			} else {
				setIsChatBlocked(true);
			}
		} else if (conversation?.transportType === MessagingTransportTypeEnum.VIBER) {
			if (contact?.lastMessageAt) {
				setIsChatBlocked(false);
			} else {
				setIsChatBlocked(true);
			}

			if (
				(lastMessage as EntityEventResType).eventType ===
				EventPayloadEnum.CONTACT_UNSUBSCRIBES_FROM_VIBER
			) {
				setBlockedInputText(t('chats.viberConversationBlocked'));
				setIsChatBlocked(true);
			}
		}
	}, [contact, conversation, lastMessage, t]);

	if (!conversationId) return null;
	

	return contactOrderContext ? (
		<OrderNotice {...contactOrderContext} />
	) : isChatBlocked ? (
		<MessageInputBlocked title={blockedInputText} />
	) : (
		<MessageInput
			shouldFocusOnRender={shouldFocusOnRender}
			contactId={contactIdProp}
			conversationId={conversationId}
		/>
	);
};
