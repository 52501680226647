
import { ContactPageLayout, useDPAById } from "@heylog-app/frontend-lib/app";
import { useParams } from "react-router-dom";

export const DpaPagePublic = () => {
    const { workspaceId } = useParams();
    const { dataCheck } = useDPAById(workspaceId || '-1');
    return (

        <div style={{ margin: '1%', height: "95vh" }}>
            <object
                width={"100%"}
                style={{ height: "95vh" }}
                data={dataCheck?.filePath} />
        </div>
    )
}