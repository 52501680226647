import {
	mdiTruckFast,
	mdiArchiveCheck,
	mdiArchiveOutline,
	mdiFlagCheckered,
	mdiTrainCarCenterbeamFull,
	mdiCheckCircle,
	mdiFile,
	mdiAlertCircle,
	mdiArrowBottomRight,
} from '@mdi/js';
import Icon from '@mdi/react';

import { TimeMattersOrderStatusEnum, UserOrderStatusEnum } from '@heylog-app/shared/types';

import type { TimeMattersOrderStatus, UserOrderStatus } from '@heylog-app/shared/types';
import type { FC } from 'react';

type OrderStatusIconProps = {
	status: UserOrderStatus | TimeMattersOrderStatus;
};

export const OrderStatusIcon: FC<OrderStatusIconProps> = ({ status }) => {
	switch (status) {
		case UserOrderStatusEnum.CREATED:
			return <Icon path={mdiFile} size={0.6} />;
		case UserOrderStatusEnum.IN_TRANSIT:
			return <Icon path={mdiTruckFast} size={1} />;
		case UserOrderStatusEnum.DELIVERED:
			return <Icon path={mdiArchiveCheck} size={1} />;
		case UserOrderStatusEnum.ARCHIVED:
			return <Icon path={mdiArchiveOutline} size={0.6} />;
		case TimeMattersOrderStatusEnum.APPROACHING:
			return <Icon path={mdiArchiveOutline} size={0.6} />;
		default:
			return null;
	}
};
