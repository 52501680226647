import { Badge, Container, Grid, Stack } from '@mui/material';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useContext, useState } from 'react';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import Icon from '@mdi/react';
import { mdiEmailOutline, mdiFilterOutline } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import {
	ButtonV2,
	EmailDialogActionEnum,
	MessageContext,
	MessageDialogTypesEnum,
	ROUTES,
	TableFilterSidebar,
	useMessagesData,
	useMessagesDataTableFilters,
	useTableColumnsSettings,
} from '@heylog-app/frontend-lib/app';

import { MessagesDataGrid } from './messages-data-grid';

export const MessagesDataTable = () => {
	const [isOpenFilters, setIsOpenFilters] = useState(false);
	const navigate = useNavigate();
	const navParams = useParams();
	const apiRef = useGridApiRef();
	const { onOpenMessageDialog } = useContext(MessageContext);

	const { columns, rows } = useMessagesData();
	const { FiltersComponent, activeFiltersCount, filteredRows } =
		useMessagesDataTableFilters(rows);
	const { t } = useTranslation();

	const {
		adjustedColumns,
		handleColumnResize,
		handleColumnOrderChange,
		handleColumnVisibilityModelChange,
	} = useTableColumnsSettings({
		columns,
		apiRef,
		localStorageName: 'messages-data-table',
	});

	const handleNewMessageClick = () => {
		navigate(generatePath(ROUTES.CONTACTS.OVERVIEW, navParams));
	};

	const handleCloseFilters = () => setIsOpenFilters(false);
	const handleOpenFilters = () => setIsOpenFilters(true);

	const handleNewEmailClick = () => {
		if (onOpenMessageDialog)
			onOpenMessageDialog({
				type: MessageDialogTypesEnum.EMAIL,
				action: EmailDialogActionEnum.NEW,
				payload: {},
			});
	};

	return (
		<Grid container wrap="nowrap" columns={{ xs: 12 }} sx={{ height: '100%' }}>
			<TableFilterSidebar onClose={handleCloseFilters} isOpen={isOpenFilters}>
				{FiltersComponent}
			</TableFilterSidebar>
			<Container
				maxWidth={false}
				sx={{
					paddingBlock: '2rem',
					display: 'flex',
					flexDirection: 'column',
					overflow: 'auto',
					height: '100%',
				}}
			>
				<MessagesDataGrid
					title="navigation.messages"
					ToolbarComponent={
						<Stack direction="row" gap={2}>
							{!isOpenFilters && (
								<Badge
									color="info"
									invisible={!activeFiltersCount}
									badgeContent={activeFiltersCount}
								>
									<ButtonV2
										onClick={handleOpenFilters}
										$variant="light2"
										startIcon={<Icon path={mdiFilterOutline} size={1} />}
									>
										{t('messages.filters.title')}
									</ButtonV2>
								</Badge>
							)}
							<ButtonV2
								$variant="light2"
								onClick={handleNewEmailClick}
								startIcon={<Icon path={mdiEmailOutline} size={1} />}
							>
								{t('messages.newEmail')}
							</ButtonV2>
							<ButtonV2 $variant="light2" onClick={handleNewMessageClick}>
								{t('messages.newMessage')}
							</ButtonV2>
						</Stack>
					}
					rows={filteredRows}
					columns={adjustedColumns}
					apiRef={apiRef}
					handleColumnOrderChange={handleColumnOrderChange}
					handleColumnResize={handleColumnResize}
					handleColumnVisibilityModelChange={handleColumnVisibilityModelChange}
				/>
			</Container>
		</Grid>
	);
};
